import React, { useEffect, useReducer, useState } from 'react'
import { Pressable } from 'react-native'

import { questionList } from '../utils/questionsList'
import { Box } from './Box'
import { Question } from './Question'

const initialState = questionList

type QuestionAction = {
    type: 'RESET' | 'REMOVE'
    payload?: string
}

function reducer(state: typeof initialState, action: QuestionAction) {
    const { type, payload } = action
    switch (type) {
        case 'RESET':
            return questionList
        case 'REMOVE':
            return state.filter((item) => {
                return item != payload
            })
    }
}


export const Game = () => {
    const [state, dispatch] = useReducer(reducer, initialState)
    const [question, setQuestion] = useState(state[Math.floor(Math.random() * state.length)])

    const handlePress = () => {
        if (state.length == 1)
            dispatch({ type: 'RESET' })
        else
            dispatch({ type: 'REMOVE', payload: question })
    }

    useEffect(() => {
        //console.log(state.length)
        setQuestion(state[Math.floor(Math.random() * state.length)])
    }, [state])

    return (
        <Pressable style={{ flex: 1 }} onPress={handlePress}>
            <Box alignItems="center" flex={1} justifyContent="center">
                <Question text={question} />
            </Box>
        </Pressable>
    )


}