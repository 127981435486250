import type { NativeStackScreenProps } from '@react-navigation/native-stack'
import React, { FC } from 'react'
import { Pressable } from 'react-native'

import { Box } from '../components/Box'
import { RootStackParamList } from '../components/Navigator'
import { Text } from '../components/Text'

type Props = NativeStackScreenProps<RootStackParamList, 'WelcomeScreen'>;

export const WelcomeScreen: FC<Props> = ({ navigation }) => {

    const handlePress = () => {
        navigation.push('GameScreen')
    }

    return (
        <Pressable style={{ flex:1 }} onPress={handlePress}>
            <Box
                alignItems="center"
                flex={1}
                justifyContent="center"
                padding="m">
                <Text selectable={false} variant="headline">
                    Velkommen til
                    {' '}

                    <Text fontWeight="bold" textDecorationLine="underline">grænseoverskridende spørgsmål</Text>
                    , klik på skærmen for at komme i gang!
                </Text>
            </Box>
        </Pressable>
    )}