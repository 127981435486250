import React from 'react'

import { Box } from '../components/Box'
import { Game } from '../components/Game'

export const GameScreen = () => (
    <Box flex={1} padding="m">
        <Game />
    </Box>
)
