
import { DarkTheme, DefaultTheme, NavigationContainer } from '@react-navigation/native'
import { createNativeStackNavigator } from '@react-navigation/native-stack'
import React from 'react'
import { useColorScheme } from 'react-native'

import { GameScreen } from '../screens/GameScreen'
import { WelcomeScreen } from '../screens/WelcomeScreen'


export type RootStackParamList = {
    WelcomeScreen: undefined
    GameScreen: undefined
};

const Stack = createNativeStackNavigator<RootStackParamList>()

export const Navigator = () => {
    const colorScheme = useColorScheme()
    return (
        <NavigationContainer theme={colorScheme === 'dark' ? DarkTheme : DefaultTheme}>
            <Stack.Navigator>
                <Stack.Group screenOptions={{ headerShown: false }}>
                    <Stack.Screen component={WelcomeScreen} name="WelcomeScreen" />

                    <Stack.Screen component={GameScreen} name="GameScreen" />
                </Stack.Group>
            </Stack.Navigator>
        </NavigationContainer>
    )}
